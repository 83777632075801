<template>
  <el-dialog :title="title" :visible.sync="visible" :close-on-click-modal="false" width="550px">
    <div v-if="type === 'upper'">
      <p>{{row.quoteUpPriceRule=='01'?'预估价':row.quoteUpPriceRule=='02'?'靓机价':row.quoteUpPriceRule=='03'?'转用预估价（无靓机价）':'转用靓机价（无预估价）'}}：{{row.quoteUpPriceRule=='01'?row.prePrice:row.quoteUpPriceRule=='02'?row.initNicePrice:row.quoteUpPriceRule=='03'?row.prePrice:row.initNicePrice}}元</p>
      <p>提醒值：{{row.limitationWarnPrice}}元（{{row.limitationWarnRate}}%，{{row.limitationWarnLimit}}元上限）</p>
      <p>限制值：{{ row.limitationPrice }}元（{{row.limitationRate}}%，{{row.limitationLimit}}元上限）</p>
    </div>
    <div v-if="type === 'lower'">
      <!-- <p>当前价格：{{row.limitationLowOriPrice}}元（取最高报价）</p> -->
      <p>当前预估价：{{ lowerInfo.prePrice || '--' }}(最高报价：{{lowerInfo.maxQuotePrice || '--'}}，有预估价优先取预估价)</p>
      <p>允许下浮比例：{{lowerInfo.limitationLowRate}}%，最多下浮：{{lowerInfo.limitationLowLimit}}元</p>
      <p>报低价提醒值：{{lowerInfo.limitationLowPrice}}元</p>
    </div>
    <span slot="footer" class="dialog-footer">
      <!-- <el-button size="small" @click="visible = false">关闭</el-button> -->
    </span>
  </el-dialog>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: 'CodeDia',
  data() {
    return {
      visible: false,
      row: {},
      type: '',
      title: '',
      lowerInfo: {}
    }
  },
  methods: {
    open(row, type) {
      this.row = row
      this.type = type
      this.lowerInfo = {}
      switch (type) {
        case 'lower':
          this.title = '报低价提醒'
          this.getLowerInfo()
          break;
        case 'upper':
          this.title = '报高价提醒'
          break;
      }
      this.visible = true
    },
    getLowerInfo() {
      _api.lowPriceAlertInfo({
        orderNo: this.row.orderNo
      }).then(res => {
        this.lowerInfo = res.data
      })
    },
    // close() {
    //   this.visible = false
    // },
  }
}
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 10px;
}
</style>