<template>
  <div v-loading="loading">
    <div style="margin-bottom: 10px">
      <div>
        <span style="margin-right: 20px;font-weight: bold;">计算取值字段</span>
        <el-radio-group v-model="rulerradio" @input="rulerinput">
          <el-radio label="01">优先用预估价</el-radio>
          <el-radio label="02">优先用靓机价</el-radio>
        </el-radio-group>
      </div>
      <div v-if="rulerradio==1" style="margin: 15px 0 0 103px;">优先使用订单预估价，若订单无预估价或预估价为0时，则使用靓机价</div>
      <div v-else style="margin: 15px 0 0 103px;">优先使用靓机价，若无靓机价或靓机价为0时，则使用预估价</div>
    </div>
    <div style="margin-bottom: 15px">
      <div class="biliStyle">
        <span style="font-weight: bold;">报高价提醒规则</span>
        <el-button
          size="mini"
          @click="$refs['quoteLimitUpperRuleDia'].open()"
          icon="el-icon-plus"
          type="primary"
          round
          >添加规则</el-button
        >
      </div>
    </div>
    <div>
      <el-table border class="mt-20" :data="list" max-height="500px">
        <!-- <GlobalTable class="mt-20" style="padding-bottom:20px;" ref="GlobalTable" :columns="regionColumns" :data="list" :maxHeight="500" :isPagination="false"> -->
        <el-table-column
          type="index"
          label="序号"
          align="center"
        ></el-table-column>
        <el-table-column
          :label="rulerradio=='01'?'同机型同规格预估价':'同机型同规格靓机价'"
          width="135px"
          align="center"
        >
          <template slot-scope="{ row }">
            <span>{{ row.lower }}≤区间＜{{ row.upper }}</span>
          </template>
        </el-table-column>
        <el-table-column label="提醒值上浮比例" align="center">
          <template slot-scope="{ row }">
            <span style="color: #0981ff">{{ row.limitationWarnRate }}%</span>
          </template>
        </el-table-column>
        <el-table-column label="最多上浮" align="center">
          <template slot-scope="{ row }">
            {{ row.limitationWarnLimit }}
          </template>
        </el-table-column>
        <el-table-column label="达到提醒值开启提醒" align="center">
          <template slot-scope="{ row }">
            <el-switch
              v-model="row.isWarn"
              active-value="01"
              inactive-value="02"
              inactive-color="#bebebe"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="限制值上浮比例" align="center">
          <template slot-scope="{ row }">
            <span style="color: #0981ff">{{ row.limitationRate }}%</span>
          </template>
        </el-table-column>
        <el-table-column label="最多上浮" align="center">
          <template slot-scope="{ row }">
            {{ row.limitationLimit }}
          </template>
        </el-table-column>
        <el-table-column label="达到限制值开启限制" align="center">
          <template slot-scope="{ row }">
            <el-switch
              v-model="row.isBan"
              active-value="01"
              inactive-value="02"
              inactive-color="#bebebe"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="180">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="$refs['quoteLimitUpperRuleDia'].open(scope.row)"
              >编辑</el-button
            >
            <el-button type="danger" size="mini" @click="delItem(scope.$index)"
              >删除</el-button
            >
          </template>
        </el-table-column>
        <!-- </GlobalTable> -->
      </el-table>
    </div>
    <QuoteLimitUpperRuleDia
      ref="quoteLimitUpperRuleDia"
      @confirm="ruleChangeHandler"
    />
  </div>
</template>

<script>
import _api from "@/utils/request";
import QuoteLimitUpperRuleDia from "./QuoteLimitUpperRuleDia.vue";
export default {
  components: { QuoteLimitUpperRuleDia },
  name: "UpperLimitTable",
  data() {
    return {
      loading: false,
      regionColumns: [
        { slotName: "region" },
        { slotName: "limitationRate" },
        { slotName: "limitationLimit" },
        { slotName: "operation" },
      ],
      list: [],
      rulerradio: '',
    };
  },
  created() {
    this.getConfig();
  },
  methods: {
    rulerinput(val) {
      console.log(val);
      this.rulerradio = val;
    },
    getConfig() {
      this.loading = true;
      _api
        .limitationList()
        .then((res) => {
          this.list = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
        _api.getheightlimitationInfo().then(res => {
          this.rulerradio = res.data.quoteUpPriceRule;
        })
    },
    ruleChangeHandler(type, ruleItem) {
      if (type == "add") {
        this.list.push({
          ...ruleItem,
          _t: Math.random(),
          isBan: "01",
          isWarn: "01",
        });
      } else {
        const { id, _t } = ruleItem;
        const itemIndex = this.list.findIndex((item) => {
          if (id) return item.id === id;
          return _t === item._t;
        });
        this.$set(this.list, itemIndex, ruleItem);
      }
    },
    delItem(i) {
      this.list.splice(i, 1);
    },
    saveConfig(command, cb) {
      _api
        .savelimitationconfig({
          command,
          quoteUpPriceRule: this.rulerradio,
          // isBan: this.isBan,
          regionList: this.list,
        })
        .then(() => {
          cb();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.biliStyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
</style>